const Redirects = [
    {
        from: 'booking',
        to: 'https://bjootify.com/salon/Soest/jan-sluyter-kappers',
    },
    {
        from: 'gmaps',
        to: 'https://goo.gl/maps/QHqqmRkRn8zhjNU57',
    },
    {
        from: 'streetview',
        to: 'https://goo.gl/nLHynf',
    },
];

export default Redirects;
