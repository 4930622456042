const Heren = [
    {
        title: '',
        Knippen: '39,50',
        'Wassen, knippen en drogen': '46,00',
    },
    {
        title: 'Jeugd',
        'Knippen (t/m 12 jaar)': '31,00',
        'Knippen (13 t/m 18 jaar)': '37,50',
    },
    {
        title: 'Overig',
        'Baard knippen': '8,00',
        'Men Reshade': '21,00',
        Tondeuse: '25,00',
    },
];

export default Heren;
