import Jan from 'Assets/images/team/jan.jpg';
import Sharon from 'Assets/images/team/sharon.jpg';

const Team = [
    {
        name: 'Jan',
        description: 'Creative-Director',
        // description: 'Jan is een kapper bij SluijterKappers',
        image: Jan,
    },
    {
        name: 'Sharon',
        description: 'Master Stylist',
        // description: 'Sharon is een kapper bij SluijterKappers',
        image: Sharon,
    },
];

export default Team;
