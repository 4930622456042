const Dames = [
    {
        title: '',
        'Wassen, knippen en drogen': '64,00',
        'Wassen, knippen en föhnen': '73,00',
        'Wassen, knippen en watergolven': '73,00',
    },
    {
        title: 'Jeugd',
        'Knippen (t/m 12 jaar)': '31,00',
        'Knippen (13 t/m 18 jaar)': '40,00',
        'Knippen (19 t/m 23 jaar)': '44,00',
    },
    {
        title: 'Kleuren',
        'Dia Color Spoeling': '49,00',
        Verven: 'vanaf 54,00',
        Highlights: '79,00',
        'Highlights Lang Haar Scalp': '94,00',
        'Highlights Lang Haar': '161,00',
        'French Balayage': 'vanaf 161,00',
        'Folies kort haar geheel': '111,00',
    },
    {
        title: 'Stylen',
        Blowen: '16,00',
        'Wassen en föhnen': '34,50',
        'Wassen en watergolf': '34,50',
    },
];

export default Dames;
